.headerPortfolio {
  margin-top: 80px;
  padding: 20px 0 10px 20px;
  display: flex;
  background-color: #010101;
  @media (min-width: 1280px) {
    margin-top: 63px;
    padding: 15px 0 0 25px;
    display: flex;
    align-items: center;
  }
  @media (min-width: 1920px) {
    padding-left: 100px;
    margin-top: 79px;
  }
}

.headerPortfolio h2 {
  color: #f2efea;
}

.headerPortfolio p {
  display: none;
  @media (min-width: 1280px) {
    display: block;
    margin-left: 230px;
    color: #f2efea;
    margin-top: -15px;
  }
  @media (min-width: 1920px) {
    margin-left: 340px;
  }
}

.portfolioContainer {
  @media (min-width: 1280px) {
    display: flex;
  }
}

.categoriesContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  @media (min-width: 1280px) {
    flex-direction: column;
    min-width: 390px;
    max-width: 390px;
  }
  @media (min-width: 1920px) {
    min-width: 610px;
    max-width: 610px;
    padding-left: 100px;
  }
}

.categories {
  font-family: Morganite;
  font-size: 36px;

  margin-right: 25px;
  letter-spacing: 0.36px;
  cursor: pointer;
  @media (min-width: 768px) {
    margin-right: 19px;
    font-weight: 700;
  }
  @media (min-width: 1280px) {
    font-size: 88px;
  }
  @media (min-width: 1920px) {
    font-size: 112px;
  }
}

.activeCategorie {
  color: #f39d38;
  text-shadow: none;
}

.notActive {
  color: #010101;
  font-weight: 100;
}

.notActive:hover {
  text-shadow: -0.5px -0.5px 0px #f39d38, 0px -0.5px 0px #f39d38,
    0.5px -0.5px 0px #f39d38, -0.5px 0px 0px #f39d38, 0.5px 0px 0px #f39d38,
    -0.5px 0.5px 0px #f39d38, 0px 0.5px 0px #f39d38, 0.5px 0.5px 0px #f39d38;
}

.portfolioHeader {
  padding: 0 0 10px 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (min-width: 1280px) {
    overflow-x: auto;
  }
  @media (min-width: 1440px) {
    width: calc(100vw - 390px);
    align-items: flex-start;
  }
}

.portfolioHeader h4 {
  font-family: Oswald;
  font-size: 16px;
  font-weight: 300;
  line-height: 130%;
  @media (min-width: 1280px) {
    display: none;
  }
}

.projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;

  @media (min-width: 1280px) {
    max-height: 90vh;
    min-width: 1030px;
    justify-content: flex-start;
  }

  @media (min-width: 1920px) {
    max-width: 1300px;
  }
}

.projectThumbnail {
  margin: 20px 20px 0 0;
  width: 43%;
  padding-top: 38%;
  position: relative;
  @media (min-width: 768px) {
    width: 29%;
    padding-top: 20%;
  }
  @media (min-width: 1280px) {
    padding-top: 0;
    margin-bottom: 20px;
    width: 270px;
    height: 190px;
    cursor: pointer;
  }
  @media (min-width: 1440px) {
    margin-bottom: 30px;
    width: 323px;
    height: 242px;
    cursor: pointer;
  }
  @media (min-width: 1920px) {
    width: 408px;
    height: 302px;
  }
}

.projectThumbnail div {
  height: 100%;
}

.projectThumbnail img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.eventName {
  width: 100%;
  text-transform: uppercase;
  color: #ffffff;
  font-family: Morganite;
  background-color: rgba(16, 16, 16, 0.55);
  height: 27px;
  position: absolute;
  bottom: 0;
  padding: 5px 0 0 5px;
  @media (min-width: 1280px) {
    font-size: 28px;
    height: 38px;
  }
  @media (min-width: 1440px) {
    font-size: 32px;
    height: 42px;
    padding-left: 10px;
    letter-spacing: 1.5px;
  }
  @media (min-width: 1920px) {
    font-size: 42px;
    padding: 0 0 0 15px;
  }
}

.eventHashtag {
  text-transform: uppercase;
  font-size: 10px;
  @media (min-width: 1280px) {
    font-size: 12px;
    margin-top: 8px;
  }
  @media (min-width: 1440px) {
    font-size: 14px;
    margin-top: 10px;
  }
  @media (min-width: 1920px) {
    font-size: 16px;
  }
}

.eventHashtag span {
  color: #f39d38;
  font-size: 10px;
  @media (min-width: 1280px) {
    font-size: 12px;
  }
  @media (min-width: 1440px) {
    font-size: 14px;
  }
  @media (min-width: 1920px) {
    font-size: 16px;
  }
}

.projectComment {
  margin: 30px 20px 20px 0;
  position: relative;
  width: 100%;
  padding-top: 65%;
  @media (min-width: 768px) {
    width: 29%;
    padding-top: 20%;
    margin: 20px 20px 0 0;
  }
  @media (min-width: 1280px) {
    padding-top: 0;
    margin: 20px 20px 20px 0;
    width: 270px;
    height: 190px;
    cursor: default;
  }
  @media (min-width: 1440px) {
    margin: 20px 20px 30px 0;
    width: 323px;
    height: 242px;
  }
  @media (min-width: 1920px) {
    width: 408px;
    height: 302px;
  }
}

.projectComment img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.commentText {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(16, 16, 16, 0.65);
  font-family: Morganite;
  font-size: 22px;
  color: #ffffff;
  padding: 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
    font-size: 21px;
    padding: 0 10px;
    line-height: 110%;
  }
  @media (min-width: 1280px) {
    font-size: 21px;
    padding: 0 30px;
    line-height: 130%;
  }
  @media (min-width: 1440px) {
    font-size: 24px;
  }
  @media (min-width: 1920px) {
    font-size: 32px;
  }
}

.commentator {
  display: flex;
  text-transform: uppercase;
  font-size: 10px;
  @media (min-width: 1280px) {
    font-size: 14px;
    margin-top: 10px;
  }
  @media (min-width: 1920px) {
    font-size: 16px;
  }
}

.commentator img {
  width: 14px;
  @media (min-width: 1280px) {
    width: 17px;
  }
  @media (min-width: 1920px) {
    width: 20px;
  }
}

.helpButton {
  position: relative;
  max-height: 70px;
  margin-top: 15px;
  margin-right: 20px;
  padding: 24px;
  color: #f39d38;
  font-family: Libre Franklin;
  font-size: 18px;
  font-weight: 700;
  border: 1px solid #f39d38;
  cursor: pointer;
  width: 100%;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  background: none;
  transition: all 0.3s;
  @media (min-width: 768px) {
    width: 29%;
    margin-left: 63.5%;
    font-weight: 400;
    padding: 15px 0;
    font-size: 16px;
  }
  @media (min-width: 1280px) {
    font-size: 22px;
    text-align: center;
    max-height: fit-content;
    width: fit-content;
    margin-left: 0;
    padding: 24px;
  }
  @media (min-width: 1920px) {
    font-size: 26px;
  }
}

.helpButton::after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 0;
  height: 110%;
  top: 50%;
  left: 50%;
  background: #f39d38;
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: all 0.3s;
}

.helpButton:hover {
  color: #ffffff;
}

.helpButton:hover:after {
  width: 100%;
  opacity: 1;
}
