.clientsAreSayingHeader {
  background-color: #010101;
  padding-left: 20px;
  padding-top: 20px;
  @media (min-width: 1280px) {
    margin-left: 0;
    padding-top: 15px;
    padding-left: 26px;
    min-width: 365px;
    @media (min-width: 1920px) {
      padding-left: 100px;
      min-width: 440px;
    }
  }
}

.clientsAreSayingHeader h2 {
  color: #f2efea;
}

.backgroundContainer {
  @media (min-width: 1280px) {
    background-image: url("../../../images/1987_services.svg");
    background-position: top left;
    background-repeat: no-repeat;
    padding-bottom: 50px;
    margin-left: 20px;
  }
}

.clientsSlider {
  padding-top: 30px;
  margin-bottom: 0;
  height: 379px;
  @media (min-width: 1280px) {
    padding-top: 50px;
    border: none;
    height: 500px;
    margin-left: 360px;
  }
}

.clientsSlider div div div div div p {
  color: #010101 !important;
  border: none;
}

.slideContainer {
  height: 430px;
  border: 1px solid #000000;
  max-width: 90%;
  margin-left: 5%;
  @media (min-width: 1280px) {
    max-width: auto;
    height: 500px;
    margin-right: 30px;
  }
  @media (min-width: 1920px) {
    height: 480px;
    margin-right: 30px;
  }
}

.headerInSlider {
  border-bottom: 1px solid #000000;
  margin-bottom: 10px;
}

.headerInSlider h3 {
  font-size: 16px;
  font-weight: 700;
  margin: 20px 0 0 10px;
  @media (min-width: 1280px) {
    font-size: 20px;
  }
  @media (min-width: 1920px) {
    font-size: 24px;
  }
}

.headerInSlider p {
  font-size: 12px !important;
  margin: 4px 0 10px 16px;
  @media (min-width: 1280px) {
    font-size: 14px !important;
  }
  @media (min-width: 1920px) {
    font-size: 16px !important;
  }
}

.slideContainer p {
  font-size: 14px;
  line-height: 130%;
  margin-left: 10px;
  @media (min-width: 1280px) {
    font-size: 16px;
  }
  @media (min-width: 1920px) {
    font-size: 20px;
  }
}

.arrowsContainer {
  position: relative;
  top: 30px;
  height: 30px;
  max-width: 90%;
  margin-left: 5%;
  border-top: 1px solid #000000;
  z-index: 1;
  @media (min-width: 768px) {
    display: none;
  }
}

.nextClients,
.prevClients {
  position: absolute;
  top: 430px;
  margin-top: -5px;
  padding-top: 5px;
  z-index: 1000;
  @media (min-width: 768px) {
    top: 470px;
  }
  @media (min-width: 1280px) {
    top: 550px;
  }
  @media (min-width: 1920px) {
    top: 550px;
  }
}

.nextClients {
  right: 16px;
  padding-right: 10px;
  @media (min-width: 1280px) {
    border-right: none;
  }
}

.prevClients {
  padding-left: 11px;
  left: 10px;
  @media (min-width: 768px) {
    border-left: none;
    left: auto;
    right: 60px;
  }
  @media (min-width: 1920px) {
    right: 75px;
  }
}

.nextClients p,
.prevClients p {
  font-size: 20px;
  &:hover {
    color: #f39d38;
  }
  @media (min-width: 768px) {
    font-size: 30px;
  }
  @media (min-width: 1920px) {
    font-size: 40px;
  }
}

.desktopText {
  margin: 60px 20px;
  text-align: center;
  @media (min-width: 768px) {
    margin-top: 150px;
  }

  & span {
    color: #f39d38;
    font-size: 62px;
    line-height: 60%;
    @media (min-width: 1280px) {
      font-size: 118px;
    }
  }
}

.desktopText h2 {
  font-size: 48px;
  font-weight: 500;
  text-transform: uppercase;
  @media (min-width: 768px) {
    font-size: 62px;
  }
  @media (min-width: 1280px) {
    font-size: 118px;
    font-weight: 700;
  }
  @media (min-width: 1920px) {
    font-size: 148px;
  }
}
