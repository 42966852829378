.headerContainer {
  height: 80px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #010101;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: #010101;
  @media (min-width: 1280px) {
    height: 64px;
    padding-left: 26px;
  }
  @media (min-width: 1920px) {
    height: 80px;
    padding: 0 100px;
  }
}

.burgerMenu {
  cursor: pointer;
  padding-right: 20px;
  @media (min-width: 1280px) {
    display: none;
  }
}

.navigationMenu {
  display: none;
  @media (min-width: 1280px) {
    padding-top: 22px;
    display: flex;
    margin-right: 26px;
    height: 100%;
  }
  @media (min-width: 1920px) {
    padding-top: 32px;
  }
}

.navigationMenu a,
.navigationMenu li {
  font-weight: 400;
  font-size: 16px;
  margin-left: 80px;
  cursor: pointer;
  color: #f2efea;
  @media (min-width: 1920px) {
    margin-left: 90px;
  }
}

.submenu {
  background-color: #f2efea;
  width: 205px;
  border: 1px solid;
  border-top: none;
  display: none;
  position: absolute;
  top: 63px;
}

.submenu a {
  color: #010101;
}

.services {
  right: 310px;
}

.about {
  right: 120px;
}

.active {
  display: block;
}

.submenu a {
  margin: 8px 5px;
  padding: 3px 5px;
  border: 0.5px solid rgba(1, 1, 1, 0.25);
  display: block;
  font-size: 14px;
  line-height: 18.2px;
  width: 95%;
}

.navigationMenuBurger {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  position: absolute;
  left: 0;
  top: 80px;
  padding-top: 20px;
  padding-left: 20px;
  background-color: #f2efea;
  height: calc(100vh - 80px);
}

.navigationMenuBurger p {
  font-size: 20px;
}

.navigationMenuBurger a {
  margin-bottom: 20px;
}

.navigationMenuBurger div {
  margin-bottom: 10px;
}

.submenuBorder {
  border: none;
  position: unset;
}

.withSubmenu {
  display: flex;
}
