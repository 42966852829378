.outerContactsContainer {
  @media (min-width: 1280px) {
    border-bottom: 1px solid #010101;
  }
}

.headerContacts {
  margin-top: 80px;
  padding: 20px 0 10px 20px;
  display: flex;
  background-color: #010101;
  @media (min-width: 1280px) {
    margin-top: 64px;
    padding: 0 0 0 25px;
    display: flex;
    align-items: center;
  }
  @media (min-width: 1920px) {
    padding-left: 100px;
  }
}

.headerContacts h2 {
  color: #f2efea;
  @media (min-width: 1280px) {
    padding-right: 200px;
    padding-top: 15px;
  }
  @media (min-width: 1920px) {
    padding-right: 245px;
  }
}

.headerContacts p {
  display: none;
  @media (min-width: 1280px) {
    display: block;
    color: #f2efea;
    margin-left: 15px;
  }
}

.mobileHeaderText {
  margin: 10px 0 0 20px;
  @media (min-width: 1280px) {
    display: none;
  }
}

.contactsContainer {
  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    margin-left: 5px;
  }
  @media (min-width: 1920px) {
    margin-left: 80px;
  }
}

.contactsContainer div {
  height: 140px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @media (min-width: 768px) {
    min-width: 47%;
    min-height: 164px;
    margin: 10px;
  }
  @media (min-width: 1280px) {
    min-width: 46%;
    min-height: 295px;
  }
  @media (min-width: 1920px) {
    min-height: 369px;
  }
}

.contactsContainer div h3 {
  color: #f39d38;
  font-family: Morganite;
  font-size: 44px;
  font-weight: 700;
  margin: 0 0 10px 20px;
  @media (min-width: 1280px) {
    font-size: 64px;
  }
  @media (min-width: 1920px) {
    font-size: 72px;
  }
}

.contactsContainer div a {
  color: #f2efea;
  margin: 0 0 15px 20px;
  font-size: 22px;
  @media (min-width: 1280px) {
    font-size: 32px;
    margin-bottom: 50px;
  }
  @media (min-width: 1440px) {
    margin-bottom: 30px;
  }
  @media (min-width: 1920px) {
    font-size: 38px;
    margin-bottom: 70px;
  }
}

.requestServices {
  background: url("../images/Contacts/requestServicesBack.jpg") no-repeat;
  background-size: 100% auto;
}

.partnership {
  background: url("../images/Contacts/partnershipBack.jpg") no-repeat;
  background-size: 100% auto;
}

.accounting {
  background: url("../images/Contacts/accountingBack.jpg") no-repeat;
  background-size: 100% auto;
}

.career {
  background: url("../images/Contacts/careerBack.jpg") no-repeat;
  background-size: 100% auto;
}

.socialLinksContainer {
  margin: 0 20px 30px 20px;
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    margin-left: 25px;
  }
  @media (min-width: 1920px) {
    margin-left: 100px;
  }
}

.socialLinksContainer h4 {
  @media (min-width: 1280px) {
    font-size: 32px;
  }
  @media (min-width: 1920px) {
    font-size: 38px;
  }
}

.socialLogos {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  @media (min-width: 768px) {
    width: 50%;
    margin-left: 60px;
    justify-content: flex-start;
  }
  @media (min-width: 1920px) {
    margin-left: 80px;
  }
}

.socialLogos a:not(:last-child) {
  margin-right: 28px;
  @media (min-width: 1280px) {
    margin-right: 57px;
  }
  @media (min-width: 1920px) {
    margin-right: 80px;
  }
}

.socialLogos a img {
  @media (min-width: 768px) {
    width: 48px;
  }
  @media (min-width: 1280px) {
    width: 58px;
  }
  @media (min-width: 1920px) {
    width: 68px;
  }
}
