.whoWeAreHeader {
  padding: 20px 20px 0 20px;
  background-color: #010101;
  @media (min-width: 1280px) {
    padding: 0;
    padding-left: 26px;
    display: flex;
    align-items: center;
  }
  @media (min-width: 1920px) {
    padding-left: 100px;
  }
}
.whoWeAreHeader h2 {
  @media (min-width: 1280px) {
    min-width: 340px;
    padding-top: 16px;
  }
  @media (min-width: 1920px) {
    min-width: 440px;
  }
}

.whoWeAreHeader p {
  margin-left: 16px;
  width: 100%;
}

.whoWeAreHeader h2,
.whoWeAreHeader p {
  color: #f2efea;
}

.outerContainer {
  @media (min-width: 1280px) {
    display: flex;
    padding-left: 26px;
  }
  @media (min-width: 1920px) {
    padding-left: 100px;
  }
}

.emptyContainer {
  display: none;
  @media (min-width: 1280px) {
    display: block;
    min-width: 340px;
    border-right: 1px solid #000000;
  }
  @media (min-width: 1920px) {
    min-width: 440px;
  }
}

.bigLogo {
  @media (min-width: 768px) {
    position: relative;
    z-index: 1;
  }
  @media (min-width: 1440px) {
    background-image: url("../../../images/1987.svg");
    height: 470px;
    background-repeat: no-repeat;
    background-position: top right;
  }
  @media (min-width: 1920px) {
    background-image: url("../../../images/1987_wide.svg");
    height: 590px;
  }
}

.whoWeAreText {
  margin-top: 10px;

  @media (min-width: 1280px) {
    margin-top: 20px;
  }
  @media (min-width: 1920px) {
    margin-top: 30px;
  }
}

.bigLogoMobile {
  height: 269px;
  background-image: url("../../../images/1987mob.svg");
  background-repeat: no-repeat;
  background-position: center;
  margin-top: -30px;
  @media (min-width: 768px) {
    width: 269px;
    position: absolute;
    right: 20px;
    top: 200px;
    z-index: 0;
  }
  @media (min-width: 1280px) {
    background-image: url("../../../images/1987_1.svg");
    height: 580px;
    width: 580px;
    top: 285px;
    margin-top: -140px;
  }
  @media (min-width: 1440px) {
    display: none;
  }
}
.whoWeAreLinks {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: space-between;
  @media (min-width: 768px) {
    width: 450px;
    justify-content: flex-start;
    position: relative;
    z-index: 10;
  }
  @media (min-width: 1440px) {
    width: 540px;
    margin-top: -200px;
  }
  @media (min-width: 1920px) {
    width: 600px;
  }
}

.whoWeAreLinks li {
  border: 0.5px solid #010101;
  margin-bottom: 10px;
  padding: 0 4px;

  @media (min-width: 768px) {
    margin-right: 15px;
    display: flex;
    align-items: center;
  }

  @media (min-width: 1280px) {
    font-size: 20px;
    margin-bottom: 16px;
    margin-right: 15px;
  }
  @media (min-width: 1920px) {
    font-size: 24px;
    margin-bottom: 16px;
    margin-right: 15px;
  }
}

.whoWeAreLinks li a {
  font-size: 14px;
  font-weight: 300;
  @media (min-width: 1280px) {
    font-size: 20px;
  }
  @media (min-width: 1920px) {
    font-size: 24px;
  }
}

.whoWeAreTextBordered {
  margin-top: 0;
  text-transform: uppercase;
  width: 100%;
  @media (min-width: 768px) {
    width: 450px;
    z-index: 10;
  }
  @media (min-width: 1440px) {
    width: 100%;
    margin-top: 40px;
  }
}

.whoWeAreTextBordered li {
  font-size: 14px;
  font-weight: 300;
  padding: 2px 4px;
  @media (min-width: 1280px) {
    font-size: 20px;
  }
  @media (min-width: 1920px) {
    font-size: 24px;
  }
}

.boderedTextHeader {
  @media (min-width: 1440px) {
    margin-top: 20px;
  }
}

.boderedTextHeader p {
  margin-bottom: 10px;
  @media (min-width: 1280px) {
    margin-bottom: 16px;
  }
  @media (min-width: 1920px) {
    margin-bottom: 30px;
    margin-top: 10px;
  }
}

.boldText span {
  font-weight: 400;
  font-size: 18px;
}

.whoWeAreFooter {
  margin-top: 20px;
  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.whoWeAreFooter li {
  margin-bottom: 10px;
  @media (min-width: 768px) {
    margin-right: 20px;
    width: 155px;
  }
  @media (min-width: 1280px) {
    margin-right: 30px;
    width: 250px;
  }
  @media (min-width: 1920px) {
    width: 345px;
  }
}

.whoWeAreFooter li a {
  border: 1px solid #010101;
  padding: 10px 0;
  font-weight: 400;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: none;
  transition: all 0.3s;
  @media (min-width: 768px) {
    font-size: 14px;
  }
  @media (min-width: 1280px) {
    padding: 15px 0;
  }
  @media (min-width: 1440px) {
    font-size: 22px;
  }
  @media (min-width: 1920px) {
    font-size: 32px;
    padding: 20px 0;
  }
}

.whoWeAreFooter li a::after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 0;
  height: 110%;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translateX(-50%) translateY(-50%);
  transition: all 0.3s;
  background: #010101;
}

.whoWeAreFooter li a:hover {
  color: #ffffff;
}

.whoWeAreFooter li a:hover:after {
  width: 100%;
  opacity: 1;
}

.whoWeAreFooter li:last-child {
  margin-right: 0;
}

.textContainer {
  padding: 20px;
  @media (min-width: 768px) {
    position: relative;
  }
  @media (min-width: 1280px) {
    padding: 40px 16px;
    width: calc(100vw - 364px);
  }
  @media (min-width: 1440px) {
    padding: 30px 15px 10px 15px;
  }
  @media (min-width: 1920px) {
    padding: 40px 15px 30px 15px;
  }
}
