@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,400;0,500;0,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Babylonica&display=swap");

html {
  scroll-behavior: smooth;
}

* {
  padding: 0px;
  margin: 0px;
  border: none;

  font-family: Oswald, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  color: #2a2a2a;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Links */

a,
a:link,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

/* Common */

aside,
nav,
footer,
header,
section,
main {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-size: inherit;
  font-weight: inherit;
}

ul,
ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

img,
svg {
  display: block;
  max-width: 100%;
  height: auto;
}

address {
  font-style: normal;
}

/* Form */

input,
textarea,
button,
select {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
}

input::-ms-clear {
  display: none;
}

button,
input[type="submit"] {
  display: inline-block;
  box-shadow: none;
  background-color: transparent;
  background: none;
  cursor: pointer;
}

input:focus,
input:active,
button:focus,
button:active {
  outline: none;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

label {
  cursor: pointer;
}

legend {
  display: block;
}

/* 420, 562, 768, 1024, 1680*/

.container {
  display: block;
  min-width: 320px;
  max-width: 419px;

  padding: 12px 20px;

  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: 420px) {
    min-width: 420px;
    max-width: 420px;
    padding: 12px 40px;
  }
  @media screen and (min-width: 562px) {
    min-width: 562px;
    max-width: 562px;
    padding: 40px 24px 40px 24px;
  }

  @media screen and (min-width: 768px) {
    min-width: 768px;
    max-width: 768px;
    padding: 50px 60px 50px 60px;
  }

  @media screen and (min-width: 1024px) {
    min-width: 1024px;
    max-width: 1024px;
    padding: 60px 140px 60px 140px;
  }

  @media screen and (min-width: 1680px) {
    min-width: 1680px;
    max-width: 1680px;
    padding: 60px 200px 60px 200px;
  }
}
