.newsWrapper {
  margin-top: 24px;
  @media (min-width: 768px) {
    margin-top: 40px;
  }

  @media (min-width: 1280px) {
    display: grid;
    grid-template: auto / repeat(2, 1fr);
    gap: 32px;
    align-items: flex-start;
    margin: 50px 26px 0 26px;
  }
  @media (min-width: 1920px) {
    margin: 60px 99px 0 99px;
  }
}

.new {
  margin: 0 20px 24px 20px;
  @media (min-width: 768px) {
    margin: 0 22px 40px 22px;
  }
}

.imageSection {
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    width: 100%;
    height: 100%;
  }
}

.imageSection img {
  margin-bottom: 12px;
  object-fit: cover;
  width: 100%;
}

.textSection p:not(:last-child) {
  margin-bottom: 12px;
  @media (min-width: 1280px) {
    margin-bottom: 50px;
  }
}

.textSection p {
  @media (min-width: 768px) {
    font-size: 18px;
  }
  @media (min-width: 1280px) {
    font-size: 22px;
  }
}

.textSection p span {
  font-size: 16px;
  font-weight: 500;
  @media (min-width: 768px) {
    font-size: 18px;
  }
  @media (min-width: 1280px) {
    font-size: 22px;
  }
}

.imageSection.twoImages {
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.servicesButtonContainer {
  background-image: url("../images/1987_services.svg");
  background-size: cover;
  width: 100%;
  height: 355px;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -140px auto -110px auto;

  @media (min-width: 768px) {
    width: 444px;
    height: 446px;
    margin-top: -150px;
    margin-bottom: -85px;
  }

  @media (min-width: 1280px) {
    margin-top: -90px;
    margin-bottom: 30px;
    width: 486px;
    height: 489px;
  }

  @media (min-width: 1920px) {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 486px;
    height: 489px;
  }
}

.quot {
  background-color: #f39d38;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 38px;
  margin: 0 20px;
  font-weight: 500;
  font-size: 18px;

  @media (min-width: 768px) {
    width: 154px;
    height: 46px;
    font-weight: 400;
  }

  @media (min-width: 1280px) {
    width: 250px;
    height: 58px;
  }

  @media (min-width: 1920px) {
    width: 343px;
    height: 72px;
  }
}
